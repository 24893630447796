import React, { Component } from "react";
import LoadingScreen from "react-loading-screen";
import { Redirect } from "react-router-dom";
import SHlogo from "../assets/img/sh_green.png";

const LoadingSpinner = (props) => {
  if (props.child) {
    
    if (props.accountInfo.account.idToken.roles.some( ele => props.permissions.includes(ele))) {
      return (
        <LoadingScreen 
          loading={props.loading}
          bgColor="#f1f1f1"
          spinnerColor="#136A32"
          textColor="#676767"
          logoSrc={SHlogo}
          text="Trwa ładowanie..."
          style={{ borderRadius: "unset" }}
        >
          {props.children}
        </LoadingScreen>
      );
    } else {
      return <Redirect to="/uprawnienia" />;
    }
  } else {
    return (
      <LoadingScreen
        loading={true}
        bgColor="#f1f1f1"
        spinnerColor="#136A32"
        textColor="#676767"
        logoSrc={SHlogo}
        text="Trwa ładowanie..."
        style={{ borderRadius: "unset" }}
      />
    );
  }
};

export default LoadingSpinner;
