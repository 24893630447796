import React, { Component, Suspense } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { CSpinner } from "@coreui/react-pro";
import { MsalProvider } from "@azure/msal-react";
import "./scss/style.scss";
import LoadingSpinner from "./components/LoadingSpinner";
import { RouteGuard } from "./components/RouteGuard";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { Helmet } from "react-helmet";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login"));
const Page404 = React.lazy(() => import("./views/Page404"));
const Page401 = React.lazy(() => import("./views/Page401"));



const App = ({ instance }) => {
  return (
    <HashRouter>
      <Helmet>
        <title>eSH</title>
      </Helmet>
      <MsalProvider instance={instance}>
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Logowanie"
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
          </Switch>
        </Suspense>
      </MsalProvider>
    </HashRouter>
  );
};

export default App;
