import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { CCol, CContainer, CRow } from "@coreui/react-pro";

export const RouteGuard = ({ Component, ...props }) => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const onLoad = async () => {
    const currentAccount = instance.getActiveAccount();

    if (currentAccount && currentAccount.idTokenClaims["roles"]) {
      let intersection = props.roles.filter((role) =>
        currentAccount.idTokenClaims["roles"].includes(role)
      );

      if (intersection.length > 0) {
        setIsAuthorized(true);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [instance]);

  return (
    <>
      {isAuthorized ? (
        <Route
          {...props}
          render={(routeProps) => <Component {...routeProps} />}
        />
      ) : (
        <div className="bg-light d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md="6">
                <div className="clearfix">
                  <h1 className="float-start display-3 me-4">401</h1>
                  <h4 className="pt-3">Brak uprawnień.</h4>
                  <p className="text-medium-emphasis float-start">
                    Nie posiadasz uprawnień do otworzenia tej strony.
                  </p>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      )}
    </>
  );
};
